.select-input .MuiInputLabel-formControl {
    opacity: 1;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.42857;
}

.select-input .MuiInputLabel-formControl.invalid {
    color: #f44336 !important;
}

.select-input .MuiFormControl-fullWidth {
    width: 100%;
    margin: 0 0 17px 0;
    position: relative;
    vertical-align: unset;
}

.MuiFormHelperText-root.helper-error {
    color: #f44336;
}