.schedule-detail {

}
.schedule-detail .rt-resizable-header-content {
    text-align: center;
}
.schedule-detail-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.schedule-detail-name {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
}
.schedule-detail-content .schedule-detail-data {
    display: flex;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    padding: .5rem 0 !important;
}
.schedule-detail-data b {
    padding-right: .5rem;
}
.schedule-detail-clinic {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 1rem 0;
}
.schedule-detail-notes {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #c3c3c3; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 12px;
}
.schedule-detail-table {
    font-size: 12px;
    max-height: 200px;
}