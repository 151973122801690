.flex-end {
    justify-content: flex-end !important;
}
.MuiInput-underline:before {
    border-color: #D2D2D2;
}
.MuiInput-underline:after {
    border-bottom-width: 1px !important;
}
.edit-customer-qr svg,
.edit-customer-qr canvas {
    height: auto !important;
    max-width: 100%;
}

.rt-td .MuiCheckbox-root {
    cursor: default;
}

.rt-td .MuiCheckbox-root:hover {
    background-color: transparent !important;
}
.nationality-autocomplete {
    padding: 0 15px;
}
.nationality-input {
    margin-top: 8px !important;
}
.actions-buttons {
    width: 100%;
}
.calendarPicker {
	z-index: 5;
}

@media (min-width: 600px) {
    .edit-customer-form .MuiGrid-grid-sm-7,
    .MuiDialogContent-root .MuiGrid-item:not(.check-item) .MuiGrid-grid-sm-7 {
        max-width: 100%;
        flex-basis: 100%;
    }
}
