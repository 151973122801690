.speciality-group>.speciality-item>.title-item,
.assign-professionals-form>div>.title-item {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: inherit !important;
    font-size: 1em !important;
    padding: 10px !important;
}
.assign-professionals-form>div>.title-module {
    margin-bottom: 20px;
}
.title-item small {
    display: block;
    font-size: 75%;
    margin-bottom: 10px;
    opacity: .7;
}
.modules-form {
    width: 100%;
}
.form-module-container {
    width: 100%;
    padding: 0 15px;
}
.form-module-container>.MuiGrid-item {
    padding: 0 !important;
}
.ps-child {
    padding-bottom: 0px !important;
    padding-left: 0px !important;
}
.form-module-container .schedule-form .MuiGrid-item {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
}
.form-module-container .schedule-form .date-input {
    width: 50%;
    max-width: 50%;
}
.schedule-detail .ReactTable .rt-td {
    display: flex;
    justify-content: center;
    align-items: center;
}
.schedule-form-submit {
    display: flex;
    justify-content: flex-end;
}
.schedule-action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}
.schedule-grid-submit {
    display: flex;
}
.MuiGrid-item.availability-label {
    margin-top: 9px;
}
.speciality-item-padding {
    margin-bottom: 20px;
}
.availability-label label{
    padding: 0 15px;
}
.availability-label label.label-text,
label.schedule-plan-title,
h3.schedule-plan-title {
    color: #002859;
    font-size: 1rem;
    font-weight: 500;
    padding: 15px 15px 0;
}

.schedule-errors {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto';
}

.schedule-errors-item .MuiIcon-colorDisabled {
    color: orange;
}
.schedule-errors-item .material-icons {
    margin-right: 8px;
}

.schedule-errors-actions {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}

.schedule-errors-actions button + button {
    margin-left: 15px;
}

.disabled-button {
    background-color: #939393 !important;
}

.MuiGrid-item.schedule-form-submit {
    padding-top: 35px !important;
}

.diagnostic-form-autocomplete {
    margin-bottom: 2vh;
    margin-top: 2vh;
}

.diagnostic-form-text-field {
    margin-top: 2vh !important;
    margin-bottom: 2vh !important;
}

.diagnostic-form-textarea {
    padding: 0px;
}

.MuiInputBase-input.Mui-disabled {
    color: #495057;
}

.rdt.disabled input {
    color: #495057 !important;
}

.customer-form-container, .responsable-form-container {
    height: 130px;
}
.MuiDialog-paperScrollPaper {
    max-height:  calc(100% - 6px) !important;
}

.title-shifts small {
    margin: 0;
}
