/* colors */
.danger {
  color: #ea5757;
}
/* H */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3c4858;
  font-weight: 300;
  line-height: 1.5em;
}
h1 {
  font-size: 2.6em;
}
h2 {
  font-size: 1.825em;
  margin: 20px 0 10px;
}
h3 {
  font-size: 1.5em;
  margin-top: 10px;
  margin-bottom: 10px;
}
h4 {
  font-size: 1.35em;
  line-height: 1.4em;
  margin-bottom: 15px;
}
h5 {
  font-size: 1.21em;
  margin-bottom: 15px;
}
h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}
h2,
h3 {
  line-height: 1.4em;
}
h3,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
p {
  margin: 0 0 10px;
}
/* general classes */
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.padding-horizontal {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.padding-vertical {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.no-padding,
.MuiGrid-root.no-padding {
  padding: 0 !important;
}
.margin-top {
  margin-top: 15px !important;
}
.required-text {
  color: #999;
  font-size: 1em;
  font-weight: 300;
  margin-bottom: 10px;
  padding: 10px 15px;
}
.divider-root {
  background-color: rgba(0, 0, 0, 0.12);
  border: none;
  flex-shrink: 0;
  height: 1px;
  margin: 10px 0;
  width: 100%;
}
.divider-light {
  background-color: rgba(0, 0, 0, 0.08);
}
.card-icon-title {
  font-size: 1.5em;
  margin-top: 15px;
  min-height: auto;
  margin-bottom: 0;
}
.card-icon-title small {
  color: #777;
  font-size: 80%;
}
.cardTitle {
  color: #999;
  font-weight: 300;
  font-size: 1em;
  margin-top: 10px;
}
.cardValue {
  color: #3c4858;
  font-size: 1.85em;
  font-weight: 300;
}
/* forms */
.rdt,
.base-font {
  font-size: 14px;
}
.CustomInput-input-473 {
  color: currentColor !important;
}
.MuiInputBase-root .MuiInputBase-input {
  font-family: 'Roboto';
  font-size: 0.875em;
  font-weight: 400;
  color: #808589;
}
.date-input .rdt input.form-control,
.date-size .rdt input.form-control {
  font-size: 1em;
}
.date-size .date-input .rdt input.form-control {
  font-size: 0.875em;
}
.MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input {
  font-size: 0.875em;
}
.rdt input.form-control {
  color: #495057 !important;
  font-size: 0.875em;
}
.date-input .rdt input.form-control:disabled {
  color: #808589 !important;
}
.MuiInputBase-input.Mui-disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}
.MuiInputLabel-root.MuiInputLabel-animated {
  color: rgba(0, 0, 0, 0.54) !important;
}
.MuiAutocomplete-option,
.MuiListItem-root.Mui-selected,
.MuiChip-label,
.mdc-chip__text {
  color: #495057 !important;
}
.radio-input {
  display: inherit;
}
.radio-input-inline .radio-input {
  display: inline-flex;
  vertical-align: middle;
}
.radio-input-inline .radio-legend {
  display: inline-flex;
  flex-direction: row;
  margin-right: 15px;
}
.radio-legend {
  opacity: 1;
  font-size: 1em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.42857;
  color: rgba(0, 0, 0, 0.54);
}
.MuiAutocomplete-listbox li.MuiAutocomplete-option {
  font-size: 0.875em;
}
.MuiAutocomplete-clearIndicator {
  top: -3px;
}
.MuiAutocomplete-popupIndicator {
  top: -5px;
}
.MuiAutocomplete-popupIndicator .MuiIconButton-label svg {
  vertical-align: middle;
}
.MuiChip-root.MuiAutocomplete-tag {
  height: 28px;
}
.MuiChip-label {
  padding-right: 6px !important;
}
.MuiMenuItem-root {
  color: #495057 !important;
  font-size: 1em !important;
}
.filters .select-input-container {
  min-width: 100%;
}
.filters .mdc-chip-set .MuiPaper-elevation1 {
  z-index: 3;
  position: absolute;
}
.filters-actions-appointments {
  margin-top: 24px;
}
.filters-header .filters-actions-appointments {
  padding: 18px 0 0 !important;
}
.filters-actions .MuiButtonBase-root {
  padding: 8px 20px;
}
.MuiPaper-root .MuiDialogContent-root {
  padding: 8px 20px;
}
.mdc-chip-set .MuiDialogContent-root .MuiGrid-root {
  padding: 0 !important;
}
.customInputChipSelect .MuiAutocomplete-clearIndicator {
  padding: 0 !important;
}
.customInputChipSelect .MuiAutocomplete-popupIndicator {
  padding: 0 !important;
}
.select-input .MuiFormControl-fullWidth {
  margin-top: 9px;
  padding-top: 0;
}
/* charts */
.chart-references {
  margin-top: 10px;
}
.chart-references.by-icon-title {
  padding-left: 78px;
}
.chart-references .item-reference + .item-reference {
  margin-top: 8px;
}
.chart-references .item-reference span {
  color: rgb(82, 80, 80);
  display: inline-block;
  vertical-align: middle;
}
.chart-references .item-reference .reference {
  height: 10px;
  margin-right: 5px;
  padding: 5px;
  width: 10px;
}
.chart-legend {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.75rem;
  text-align: center;
}
.chart-legend-blue {
  color: #00bcd4;
}
.chart-legend-red {
  color: #ea5757;
  margin-left: 10px;
}
.chartist-graph-lines-green .ct-series-a .ct-line,
.chartist-graph-lines-green .ct-series-a .ct-point,
.chart-legend-green {
  color: green;
  stroke: green;
}
.chartist-graph-lines-black .ct-series-a .ct-line,
.chartist-graph-lines-black .ct-series-a .ct-point,
.chart-legend-black {
  stroke: black;
  color: black;
}
.chartist-graph-lines-purple .ct-series-a .ct-line,
.chartist-graph-lines-purple .ct-series-a .ct-point,
.chart-legend-purple {
  stroke: purple;
  color: purple;
}
/* tables */
.table-container .footer-buttons {
  padding: 0 5px 0;
}
.table-container .buttons-right {
  justify-content: flex-end;
}

.filtrable-table {
  z-index: 1;
}
.toolTipEdit {
  width: 40px !important;
  float: left;
}

.toolTipEdit .svgIcon {
  opacity: 0.65;
}

.chipEllipsis .mdc-chip__text {
  max-width: 300px;
  overflow: hidden;
  height: 20px;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

.check-label .MuiIconButton-label {
  max-width: 20px;
  max-height: 20px;
}

.dashboard-table {
  max-height: 50%;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  line-height: 1.5;
}

/* events Calendar colors */
.rbc-event {
  background-color: #abaeb3;
  border: none;
  border-radius: 2px;
  display: block; /* make the <a> tag block */
  font-size: 0.85em;
  font-weight: normal; /* undo jqui's ui-widget-header bold */
  line-height: 1.2;
  padding: 0 0 1px;
  position: relative; /* for resize handle and other inner positioning */
  text-align: left;
  width: 100%;
}
.rbc-event.event-1F3FB9 {
  background-color: rgb(31, 63, 185);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(31, 63, 185, 0.4);
}
.rbc-event.event-E157CA {
  background-color: rgb(225, 87, 202);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(225, 87, 202, 0.4);
}
.rbc-event.event-F06292 {
  background-color: rgb(240, 98, 146);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(240, 98, 146, 0.4);
}
.rbc-event.event-5AB4FF {
  background-color: rgb(90, 180, 255);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(90, 180, 255, 0.4);
}
.rbc-event.event-D81B60 {
  background-color: rgb(216, 27, 96);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(216, 27, 96, 0.4);
}
.rbc-event.event-0A85FF {
  background-color: rgb(10, 133, 255);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(10, 133, 255, 0.4);
}
.rbc-event.event-E8DD6C {
  background-color: rgb(230, 145, 0);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(230, 145, 0, 0.4);
}
.rbc-event.event-BF00B0 {
  background-color: rgb(191, 0, 176);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(191, 0, 176, 0.4);
}
.rbc-event.event-DEC02B {
  background-color: rgb(222, 192, 43);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(222, 192, 43, 0.4);
}
.rbc-event.event-880E4F {
  background-color: rgb(136, 14, 79);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(136, 14, 79, 0.4);
}
.rbc-event.event-1BD893 {
  background-color: rgb(27, 216, 147);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(27, 216, 147, 0.4);
}
.rbc-event.event-D26D0B {
  background-color: rgb(210, 109, 11);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(210, 109, 11, 0.4);
}
.rbc-event.event-default.rbc-selected {
  background-color: #abaeb3;
}
.rbc-event.event-default {
  background-color: #abaeb3;
}

.MuiButtonBase-root.MuiButton-root.button-gray {
  background-color: rgb(155, 154, 154);
  box-shadow: 0 2px 2px 0 rgb(155 154 154 / 14%),
    0 3px 1px -2px rgb(155 154 154 / 20%), 0 1px 5px 0 rgb(155 154 154 / 12%);
}
