* {
      font-family: 'Roboto';
    }

.newEmployeeTittle {
    color: #3C4858;
    font-size: 1.3em;
    line-height: 1.4em;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 15px;
    min-height: auto;
    text-decoration: none;
}


.nationality-input {
    margin-top: 8px !important;
}

.calendar-up {
    z-index: 2;
}