.error {
  color: #ea5757;
}
.warning {
  color: #ffc44b;
}
.success {
  color: #5dc4b9;
}
.event-cont {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.event-with-icon > .event-alert {
  padding-top: 2px;
}
.event-cont .MuiSvgIcon-root {
  margin: 0 3px 0 0;
  width: 0.7em;
  height: 0.7em;
}
.rbc-agenda-event-cell .event-cont .MuiSvgIcon-root {
  margin-top: 2px;
}
.event-time-title {
  align-self: flex-start;
  display: flex;
  margin-top: 2px;
}
.event-cont > .event-container-title {
  max-width: 100%;
}
.event-cont > .event-container-title > .event-title,
.event-cont > .event-title {
  display: block;
  padding: 3px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.event-with-icon > .event-container-title,
.event-with-icon > .event-title {
  font-family: 'Roboto';
  width: calc(100% - 24px);
}

.reject-icon-agenda {
  align-self: flex-end;
  float: right;
  content: '';
  display: block;
  fill: #ffffff;
  width: 18px;
  height: 18px;
  right: 2px;
  margin-left: 10px;
  float: right;
  position: relative;
}

.reject-icon {
  align-self: flex-end;
  float: right;
  content: '';
  display: block;
  fill: #ffffff;
  width: 18px;
  height: 18px;
  right: 2px;
  top: 2px;
  margin-left: 20px;
  float: right;
  position: absolute;
}
