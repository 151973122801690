.item-flex-end {
    display: inline-flex;
    justify-content: flex-end;
}
.users-container .add-content-button {
    margin-right: 15px;
}
.user-button-icon.MuiSvgIcon-root {
    margin: auto;
}
.status-tag {
    border-radius: 5px;
    font-size: .8rem;
    display: inline-block;
    padding: 5px 10px;
    text-transform: capitalize;
}
.status-tag.pending {
    background-color: #ffd866;
}

@media screen and (max-width: 976px) {
    .users-content {
        width: 100%;
    }
}
