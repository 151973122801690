.schedule-plan-title {
    font-family: "Roboto";
}

.CustomInput-labelRootError {
    color: #f44336 !important;
}

.schedule-plan-group.MuiGrid-item {
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: flex-start;
    padding: 0 !important;
}
.schedule-plan-group.MuiGrid-item .MuiGrid-grid-xs-2 {
    padding: 0 !important;
}

.MuiGrid-item.schedule-plan-submit {
    display: flex;
    justify-content: flex-end;
}

.MuiButton-root.schedule-plan-submit-btn {
    background: transparent;
    color: #3f51b5;
    border: none;
    box-shadow: none;
}

.MuiButton-root.schedule-plan-submit-btn:hover {
    background: transparent;
    color: #3f51b5;
    border: none;
    box-shadow: none;
}

.custom-label {
    font-size: 1em;
    font-weight: 400;
}

.disabled-label {
    color: #D2D2D2 !important;
}

.schedule-plan-group.no-padding {
    padding: 0 !important;
}
.schedule-plan-finish.MuiGrid-item {
    padding-left: 30px !important;
}
.schedule-plan-finish .MuiFormGroup-root .radio-input-group {
    font-size: 14px;
}
.schedule-plan-finish .radio-input .MuiFormControl-root {
    width: 100%;
}
.schedule-plan-finish .MuiFormGroup-root .radio-input-group + .radio-input-group {
    width: 100%;
}
/* .schedule-plan-finish .MuiFormGroup-root .radio-input-group + .radio-input-group .MuiFormControlLabel-root {
    flex-basis: calc(25% + 11px);
    flex-grow: 0;
    max-width: calc(25% + 11px);
} */
.schedule-plan-finish .MuiFormGroup-root .radio-input-group .MuiFormControlLabel-root {
    margin-right: 0;
}
.finishDate-group.disabled {
    opacity: .5;
}

.schedule-plan-group .suffix-label {
    opacity: .7;
}

.schedule-form-submit.plan {
    padding: 35px 40px 0 !important;
}
