.about-logo {
    margin-bottom: 8px;
    max-width: 50%;
}
.about-information-label {
    color: grey !important;
}
.about-company-info {
    margin: 1.5rem 0 .7em !important;
}
.about-company-info p {
    font-size: .875em;
}
.about-company-info img {
    width: 95px;
}