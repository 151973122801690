.gmap-container {
    position: relative;
    height: 50vh; 
    margin-top: 20px;
}
.gmap-container > div {
    max-width: calc(100% - 30px);
}
.create-address-submit {
    float: right;
}

.error {
    color: 'red';
    font-family: 'Roboto';
    font-size: 0.99em;
    font-weight: 400;
}

.item-input .MuiGrid-item {
    padding: 0 !important;
}

.map-container {
    position: relative;    
    min-height: 200px;
}
.map-container > div {
    max-width: calc(100% - 30px);
}
.item-submit {
    margin-top: 10px !important;
}
