.container {
    padding: 40px;
}
.linea {
    border-color: rgb(7,178,84);
}

.label {
    font-size: 22px;
    font-weight: bold;
    color: rgb(7,178,84);
    padding: 10px;

}
.labelError {
    color: #ff0000 !important;
    font-weight: bold;
    margin-left: 10px;
}

.footer-text {
    margin-top: 20px;
}
.stores {
    margin-top: 20px;
    margin-left: 20px;
    width: 120px;
    height: 36px;
}
.labelAccount {
    font-size: 20px;
    padding: 10px;
    padding-left: 20px;
    color: rgb(3,173,191);
    
}
.icons {
    margin-top: 5px !important;
    padding: 5px;
}
.password {
    font-size: 16px;
    color: darkgrey;
}

.labelEmail {
    font-size: 20px;
    padding: 10px;
    color: rgb(3,173,191);
}

.labelEmail {
    font-size: 20px;
    padding: 10px;
    color: rgb(3,173,191);
}

.labelPassword
{
    font-size: 20px;
    padding: 10px;
    color: rgb(3,173,191);
}


.labelDownload {
    padding-top: 20px;
    padding-left: 20px;
    padding: 10px;
}

.footer {
    margin-top: 50px;
    width: 100%;
    height: 150px;
    font-size: 18px;
    background-color: rgb(38,160,100);
}