.new-customer-form {
  display: flex;
  flex-direction: column;
}
.new-customer {
  margin-bottom: 180px;
}
.new-customer-label.MuiFormLabel-root {
  padding: 0 1rem 0 0;
}
.new-customer-row {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.new-customer-row .MuiFormControl-root {
  padding-right: 1rem;
}
.new-customer .new-customer-submit {
  justify-content: space-between;
}

.calendar-up {
  z-index: 2;
}

.select-input
  .MuiAutocomplete-inputRoot[class*='MuiInput-root']
  .MuiAutocomplete-input {
  opacity: 1;
  font-size: 14px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.42857;
  color: #495057;
}

.MuiGrid-root .MuiInputBase-input.Mui-disabled {
  color: #495057 !important;
}

@media (min-width: 600px) {
  .new-customer-form .MuiGrid-grid-sm-7 {
    max-width: 100%;
    flex-basis: 100%;
  }
}
