.container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 400px;
}

.subtitle {
  margin-top: -10px;
  margin-bottom: 60px;
}
