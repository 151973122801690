* {
  font-family: 'Roboto';
}
.radio-input-group {
  display: flex;
  flex-direction: row;
}
.MuiFormGroup-root {
  flex-direction: initial;
}
.MuiFormGroup-root {
  flex-direction: row !important;
}

.ct-label.ct-label.ct-horizontal.ct-end {
  position: relative;
  justify-content: flex-start;
  text-align: center;
  white-space: nowrap;
}

.ct-chart-bar > .ct-labels > foreignObject >.ct-label.ct-horizontal.ct-end {
  left: calc(100% / 2);
}
.radio-others-container {
  align-items: flex-end;
  display: flex;
}

.table-simple-left > .rt-table > .rt-tbody {
  text-align: left;
}
.headerVitalSigns{
  text-transform: unset;
}
.vitalSignsView .ct-horizontal{
  top: -12px;
}
.Mui-selected .HearthPulseIcon {
  filter: invert(0.23) sepia(1) saturate(10) hue-rotate(211deg)
}
.HearthPulseIcon {
  filter: invert(0.45) sepia(0);
}
.MuiTab-root {
  min-width: 148px;
}

.progressContent {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
}
