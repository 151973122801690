.customers {
    display: flex;
    justify-content: space-between;
}
.customers-content {
    width: calc(100% - 260px);
}
.customers-filters {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.customers-filters-row {
    width: 100%;
}
.customers-dropdown {
    width: 20%;
}
.customers-content-mini {
    width: calc(100% - 80px)
}
.filtrable-table .ReactTable .rt-thead.-header {
    z-index: 3;
}
.customers-content-header,
.customers-content-header-mini {
    min-height: 70px;
}
.customers-content-header header{
    width: calc(100% - 260px);
}
.customers-content-header-mini header {
    width: calc(100% - 80px)
}
.customers-content-body {
    max-height: 500px;
    overflow: auto;
    display: flex;
    justify-content: space-evenly;
}
.customers-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}
.customers-modules {
    width: 50%;
    padding: 0 1rem;
}
.customers-label.MuiFormLabel-root {
    padding: 0 1rem 0 0;
}
.customers-input {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    height: 70px;
}
.customers-content-body div {
    width: 100%;
}
.MuiFormControl-root.customers-dropdown {
    min-width: 150px;
    max-width: 150px;
}
.customerss-submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.customers-modules-button {
    float: right;
}
.customers-input-number {
    max-width: 50px;
}
.customers-dialog {
    width: 60%;
    margin: auto;
}
.customers-dialog .customers-input {
    justify-content: space-between;
}
.customers-actions {
    display: flex;
    justify-content: center;
}
.customers-actions button {
    padding: 5px;
    width: auto;
    height: auto;
    min-width: auto;
    font-size: 12px;
}
.customers-button {
    float: right;
}
.customers-button-icon.MuiSvgIcon-root {
    margin: auto;
}

@media screen and (max-width: 976px) {
    .customers-content {
        width: 100%;
    }
    .customers-content-header header{
        width: 100%;
    }
    .customers-content-body {
        flex-direction: column;
    }
    .customers-modules, .customers-form {
        width: 100%;
    }
}