.MuiPaper-root ul.MuiList-root .MuiListItem-root a .MuiListItemText-root {
  text-overflow: ellipsis;
  overflow: hidden;
}
.companyName {
  align-items: center;
  display: flex !important;
  font-family: 'Roboto';
  justify-content: center;
  margin-left: 0 !important;
  text-decoration: none !important;
}
.companyName a {
  color: inherit;
  text-decoration: none !important;
  width: 100%;
}

.logo-hmm {
  width: 8rem;
  z-index: 2000;
  margin: 3rem auto;
}
.logos a img {
  margin: 5rem;
}
.logos img.hmm {
  width: 6rem;
}
.logos img.glin {
  width: 5.5rem;
}
.closeModalTopFixButton {
  z-index: 9;
}

@media (max-width: 959.95px) {
  .Sidebar-drawerPaper-2:before {
    z-index: -1;
  }
  .backgroundTablet {
    z-index: -1;
  }
  .ps {
    z-index: 10;
    position: absolute;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .ps .Sidebar-caret-40 {
    right: 0 !important;
  }
  .ps .Sidebar-list-17 {
    margin-left: 2px;
  }
  .ps #rightMenuTopStyle {
    flex-direction: row;
    display: flex;
    place-content: center;
  }
  .ps .pooperResponsive-319 {
    top: unset !important;
  }
  .ps .pooperResponsive {
    top: unset !important;
  }
  .ps .notifications-menu div[role='tooltip'] {
    top: 54px !important;
  }
}
