.full-loading {
    background: #fff;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh !important;
    position: fixed;
    z-index: 9999;
}

.full-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.full-loading-container.white {
    background: #fff;
}

.full-loading-container.green {
    background: #009966;
}

.full-loading-title {
    width: 100%;
    text-align: center;
    color: #333;
    font-family: 'Roboto';
}

.full-loading-animation {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.full-loading .heart {
    width: 20%;
    animation-name: heartbeat;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@keyframes heartbeat {
    0%   { transform: scale(1);    }
    30%  { transform: scale(1);    }
    40%  { transform: scale(1.12); }
    50%  { transform: scale(1);    }
    60%  { transform: scale(1);    }
    70%  { transform: scale(1.08); }
    80%  { transform: scale(1);    }
    100% { transform: scale(1);    }
}
