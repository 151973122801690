/* chips filters */
.filters {
  min-height: 50px;
}
.filters .MuiCard-root {
  overflow: visible;
}
.filters .MuiCard-root .MuiCardActions-root {
  justify-content: flex-end;
  padding-left: 30px;
  padding-right: 30px;
}
.filters .MuiFormLabel-root {
  position: relative;
  padding-right: 60px;
}
.filters .MuiFormLabel-root+div{
  margin-top: 0;
}
.filters .MuiFormLabel-root+div:before {
  bottom: 5px;
}
.filters .MuiFormLabel-root+div:after {
  bottom: 5px;
}
.filters .date-input .MuiFormControl-fullWidth {
  padding-top: 0;
}
.filters .MuiAutocomplete-endAdornment {
  top: calc(50% - 20px);
}
.filters .addFilterDisabled {
  opacity: 0.7 !important;
  pointer-events: none;
}

.filters .select-input .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
  min-width: 120px;
}