
.header {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    background-color: hsl(35, 58%, 94%);
    font-size: 1.1em;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54) !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.link {
    color: blue;
    cursor: pointer;

}


.file {
    font-size: 1.1em;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54) !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}


.container {
    padding-left: 20px;
}

.no-padding {
    padding-left: -20px !important;
}

.no-data {
    padding:20px;
    width: 100%;
    text-align: 'center';
}