.status-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.status-icon.orange {
    width: 80px;
    height: 80px;
    border-width: 4px;
    border-style: solid;
    border-color: rgb(238, 162, 54);
    border-radius: 50%;
    margin: 20px auto;
    position: relative;
    box-sizing: content-box;
}

.status-icon.orange .child-1 {
    position: absolute;
    width: 5px;
    height: 47px;
    left: 50%;
    top: 10px;
    border-radius: 2px;
    margin-left: -2px;
    background-color: rgb(240, 173, 78);
}

.status-icon.orange .child-2 {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: -3px;
    left: 50%;
    bottom: 10px;
    background-color: rgb(240, 173, 78);
}

.status-icon.green {
    width: 80px;
    height: 80px;
    border-width: 4px;
    border-style: solid;
    border-color: rgb(92, 184, 92);
    border-radius: 50%;
    margin: 20px auto;
    position: relative;
    box-sizing: content-box;
}

.status-icon.green .child-1 {
    height: 5px;
    background-color: rgb(92, 184, 92);
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
    width: 25px;
    left: 14px;
    top: 46px;
    transform: rotate(45deg);
}

.status-icon.green .child-2 {
    height: 5px;
    background-color: rgb(92, 184, 92);
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
    width: 47px;
    right: 8px;
    top: 38px;
    transform: rotate(-45deg);
}

.status-icon.red {
    width: 80px;
    height: 80px;
    border-width: 4px;
    border-style: solid;
    border-color: rgb(212, 63, 58);
    border-radius: 50%;
    margin: 20px auto;
    position: relative;
    box-sizing: content-box;
}

.status-icon.red .child-1 {
    position: absolute;
    height: 5px;
    width: 47px;
    background-color: rgb(217, 83, 79);
    display: block;
    top: 37px;
    border-radius: 2px;
    transform: rotate(45deg);
    left: 17px;
}
.status-icon.red .child-2 {
    position: absolute;
    height: 5px;
    width: 47px;
    background-color: rgb(217, 83, 79);
    display: block;
    top: 37px;
    border-radius: 2px;
    transform: rotate(-45deg);
    right: 16px;
}

.status-text {
    font-family: 'Roboto';
}