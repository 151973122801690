.appointments {
    display: flex;
    justify-content: space-between;
}
.MuiGrid-container .buttons-header {
    padding-top: 22px !important;
    text-align: right;
}
.buttons-header span {
    display: inline-flex;
}
.filter-full {
    width: 100%;
}

.bottom {
    padding-bottom: 100px;
}
.buttons-header span + span {
    margin-left: 8px;
}
.appointments-content {
    width: calc(100% - 260px);
}
.appointments-dropdown {
    width: 20%;
}
.appointments-content-mini {
    width: calc(100% - 80px)
}
.appointments .filters-header {
    z-index: 4 !important;
}
.filtrable-table .ReactTable .rt-thead.-header {
    z-index: 3;
}
.appointments-content-header,
.appointments-content-header-mini {
    min-height: 70px;
}
.appointments-content-header header {
    width: calc(100% - 260px);
}
.appointments-content-header-mini header {
    width: calc(100% - 80px)
}
.appointments-content-body {
    overflow: hidden;
}

.filter {
   margin-left: 20px;
}
.filter .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    position: absolute;
    top: -50px;
    left: -150px;
}

.add-content-button {
    float: right;
    margin-top: 12px !important;
}
.add-content-button svg {
    padding-right: 6px;
}
.appointments-button-icon.MuiSvgIcon-root {
    margin: auto;
}
.appointments .rdt input.form-control {
    height: auto;
}
.appointments-actions {
    display: flex;
    justify-content: center;
}
.appointments-actions button {
    padding: 5px;
    width: auto;
    height: auto;
    min-width: auto;
    font-size: 12px;
}
.appointments-button {
    float: right;
}
.appointments-button-icon.MuiSvgIcon-root {
    margin: auto;
}
.filter-appointments > div + div{
    margin-left: 30px;
}
.filter-appointments > div {
    max-width: calc(50% - 15px);
    padding-top: 22px;
}
.appointments .select-input .MuiInputLabel-formControl {
    line-height: 0.7;
}
.svgInCard {
    padding: 4px;
    margin-top: 0px !important;
    margin-bottom: -5px
}
.svgIcon {
    height: 25px !important;
    width: 25px !important;
}
.svgdanger {
    filter: invert(0.5) sepia(1) saturate(63) hue-rotate(347deg)
}
.svginfo {
    filter: invert(0.5) sepia(1) saturate(33) hue-rotate(188deg)
}
.svgwarning {
    filter: invert(0.5) sepia(1) saturate(14) hue-rotate(349deg)
}
.svgsuccess {
    color: #00cb59;
}
.svgdefault {
    color: #adadad;
}
.svgrose {
    filter: invert(0.7) sepia(0.5) saturate(116) hue-rotate(321deg)
}
.svgwhite {
    filter: invert(1)
}
.svgyellow {
    /* filter: invert(77%) sepia() saturate(9988%) hue-rotate(0deg) */
    color: #fbc706;
}
svg.svgsuccess {
    color: #00c958;
}
.svg-assign-agenda {
    color: #d000b4;
}
.svg-cancel-agenda {
    width: 18px;
    height: 19px;
    filter: invert(34%) sepia(93%) saturate(3555%) hue-rotate(346deg) brightness(105%) contrast(91%);
}
.svg-cancel-agenda-form-card {
    width: 24px;
    height: 24px;
    filter: brightness(0) invert(1);
    padding: 2.5px 4px;
}
.MuiChip-deletable .MuiChip-deleteIcon {
    margin-top: 0 !important;
}
.appointments-content-body .rt-td {
    align-self: center;
    padding-top: -300px !important;
}
.noButton {
    width: 40px !important;
    padding-left: 0 !important;
}

.toolTipEditAppointment {
    width: 100px !important;
}

.content-end>div {
    justify-content: flex-end;
}

@media screen and (max-width: 976px) {
    .appointments-content {
        width: 100%;
    }
    .appointments-content-header header{
        width: 100%;
    }
}
.cancellation-content {
    margin-bottom: 15px !important;
}
.cancellation-content .radio-input {
    padding: 0 !important;
}

.dialog-button-renew {
    text-align: 'center';
    align-self: center;
    justify-items: center;
}

.appointmentRenewTitle {
    color: #3c4858;
    font-size: .875em;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 12px;
    text-align: center;
}
.content-actions {
    text-align: center;
}

.filter-number {
    text-align: right;
    align-items: flex-end;
    float: right;
    clear: both;
}

.appointment-header {
    margin-top: -50px !important;
}

.appointments-copy {
    margin-left: -20px !important;
    margin-top: 12px !important;
}

.appointments-filters {
    margin-bottom: 16px;
}

.justify-space-between {
    justify-content: space-between;
}

.info-search {
    text-align: right;
}
.info-search svg {
    color: #3C4858;
}

.appointments-filters .rdt input.form-control {
    height: 1.1876em;
}

.not-scroll-table .ReactTable .rt-tbody  {
    height: auto !important;
    overflow: visible;
}

.appointments-list {
    padding: 0 15px;
}

.filters-row .MuiGrid-item:not(.MuiGrid-container):not(.select-input-container) {
    padding: 0 8px !important;
}

.MuiInputLabel-root.MuiInputLabel-shrink {
    line-height: 1.1;
}
.date-container .MuiInputLabel-root.MuiInputLabel-animated {
    bottom: 30px;
    line-height: 1.2;
    top: inherit;
}

.icon-with-overlay {
    position: relative;
    display: block;
}

.icon-with-overlay .principal-icon {
    color: rgba(0, 0, 0, 0.87);
}

.icon-with-overlay .overlay-icon {
    background-color: white;
    border-radius: 50%;
    height: auto;
    left: 46%;
    position: absolute;
    top: 48%;
    width: 80%;
}
