.chechbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#input-patient {
  display: none;
}

.radio-contaiter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50rem;
  height: 4.375rem;
}

.label-chechbox-container {
  margin: 0.625rem 1.25rem 0 0;
}

.frecuency-input {
  width: 500px;
}
.frecuency-input input {
  margin: 0;
}

.label-radiobox-container {
  margin: 0rem 1.25rem 0 0;
  width: 18.75rem;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin: 1.25rem;
  width: 100%;
}

.button-submit-container {
  display: flex;
  justify-content: flex-end;
}

.container-dressing {
  display: flex;
}

.header-internment-table {
  display: flex;
  justify-content: space-between;
}

.header-buttons {
  display: flex;
  margin: 1.25rem;
}

.header-icon {
  height: 2.1875rem;
}

.button-new-int {
  height: 2.8125rem;
  margin-right: 0.625rem;
}

.modal-medication {
  width: 70%;
  margin: 0 auto;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.delete-medication-button {
  margin-top: 1.25rem;
}

.button-add-option {
  display: flex;
}

.title-pathologies-section {
  margin-left: 0.8125rem;
}

.startDate {
  padding-right: 10px;
}

.endDate {
  padding: 0 15px;
}

.add-pathology-button {
  margin: 15px;
}

.hospitalization-detail {
  margin: 12px;
}

.hospitalization-detail-date {
  font-size: 12px;
  margin-top: -5px;
  color: darkgrey;
}

.hospitalization-detail-message {
  font-size: 13px;
  margin-left: 15px;
  margin-top: -5px;
  color: rgb(201, 200, 200);
}

.hospitalization-detail-text {
  font-size: 15px;
  margin-top: -5px;
}

.no-pathology-message {
  font-size: 13px;
  margin-left: 30px;
  margin-top: -5px;
  color: rgb(201, 200, 200);
}

.error-text {
  font-size: 13px;
  margin-left: 30px;
  margin-top: -5px;
  color: #f44336;
}

.separator {
  height: 0.1px;
  background-color: rgb(201, 200, 200);
  width: 100%;
  border-style: none;
  margin: 10px 30px;
}
