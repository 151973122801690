.wrapper {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.hmm-logo {
  margin: 2.5rem 0 2rem 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hmm-logo img {
  height: 7rem;
}

.fullpage {
  display: flex !important;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.login {
  height: 45vh;
  background-image: url('../../assets/img/login//login-bkg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.loading-profile {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
span.loading-profile-welcome {
  font-size: 30px;
  color: #022e5e;
  margin: 15px 20px 0px 20px;
}

span.loading-profile-welcome::after {
  content: ',';
}

span.loading-profile-username {
  color: #022e5e;
  font-size: 30px;
  font-family: 'Roboto';
  margin-bottom: 20px;
  font-weight: bolder;
}
.circular-progress {
  color: #5dc4b9 !important;
}
#login-form {
  justify-content: center;
  align-items: center;
  display: flex;
}
.login-footer {
  background-color: #5dc4b9;
  width: 100%;
  height: 55vh;
  text-align: center;
}
.powered-by a {
  display: inline-block;
  margin-top: 38vh;
}
.powered-by img {
  height: 4.5rem;
}
.login-card {
  border-radius: 0.75rem !important;
  max-width: 420px !important;
  padding: 30px 15px;
}
.login-card .MuiFormControl-root {
  width: 100%;
  margin-top: 15px;
  background-color: #b7bdc5;
  border-radius: 10px;
}

.login-card .MuiFilledInput-adornedStart,
.login .MuiIconButton-label {
  color: #ffffff;
}

.login-remember-me .MuiIconButton-label {
  color: #022e5e !important;
}

.login-card .MuiTypography-body1 {
  color: #022e5e !important;
}

.MuiFilledInput-input {
  padding: 15px !important;
}

.MuiInputAdornment-positionStart {
  padding-bottom: 15px !important;
}

.MuiInputAdornment-positionStart .MuiSvgIcon-root {
  font-size: 2rem !important;
}

.login-header {
  text-align: center;
}

.login-header-title {
  color: #fff;
  margin-top: 0;
  min-height: auto;
  font-weight: 300;
  font-size: 1.3em;
  line-height: 1.4em;
  margin-bottom: 23px;
  text-decoration: none;
}
#login-form .MuiFilledInput-underline:after {
  border-bottom-color: #022e5e;
}
.login-sign-in {
  justify-content: center;
  flex-direction: column;
}

.signIn {
  width: 100%;
  background-color: #5dc4b9 !important;
  font-weight: bolder !important;
}
.login-recover {
  padding: 10px 40px !important;
  color: #022e5e;
  text-align: center;
}
.login-recover-link {
  text-decoration: none;
  color: #022e5e;
  font-size: 12px;
}
.login-recover-link::after {
  content: ' >';
}

/*button submit*/

.RegularButton {
  color: #fff;
  border: none;
  margin: 0.3125rem 1px !important;
  cursor: pointer;
  padding: 12px 30px;
  position: relative;
  min-width: auto;
  font-size: 12px;
  min-height: auto;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  font-weight: 400 !important;
  will-change: box-shadow, transform;
  line-height: 1.42857143;
  white-space: nowrap;
  touch-action: manipulation;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0;
  vertical-align: middle;
  background-color: #999;
}
.RegularButton-block {
  width: 100% !important;
}
button.RegularButton-lg {
  padding: 1.125rem 2.25rem;
  font-size: 0.875rem;
  line-height: 1.333333;
  border-radius: 0.2rem;
}
.RegularButton-rose {
  box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14),
    0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
  background-color: #e91e63;
}
.RegularButton-simple,
.RegularButton-simple:focus,
.RegularButton-simple:hover {
  color: #fff;
  background: transparent;
  box-shadow: none;
}
.RegularButton-simple.RegularButton-rose,
.RegularButton-simple.RegularButton-rose:focus,
.RegularButton-simple.RegularButton-rose:hover,
.RegularButton-simple.RegularButton-rose:visited {
  color: #e91e63;
}

/* Select  AdornedStart */
.auth-user-page .MuiFilledInput-underline:after {
  border-width: 1px !important;
}
.company .select-input-container {
  padding: 0 !important;
}
.company .select-input-container .MuiTextField-root {
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
}
.company .select-input-container .MuiAutocomplete-inputRoot {
  padding-left: 12px;
}
.company .select-input-container .MuiInputBase-inputAdornedStart {
  padding: 1px 12px 12px !important;
}
.company .select-input-container .MuiInputAdornment-positionStart svg {
  color: white;
}
.auth-user-page .MuiFilledInput-root {
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
}

.auth-user-page .MuiInputBase-root .MuiInputBase-input,
.auth-user-page
  .select-input
  .MuiAutocomplete-inputRoot[class*='MuiInput-root']
  .MuiAutocomplete-input,
.auth-user-page .MuiGrid-root .MuiInputBase-input.Mui-disabled {
  color: white !important;
}

.auth-user-page .select-input .MuiAutocomplete-endAdornment {
  right: 15px;
}

.chat-floating {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  z-index: 999999999;
  height: 60px;
  background-color: #2b8279;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  border: 0px solid white;
}


.chat-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}
